import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"poslugi-ta-cini"} />
		<Helmet>
			<title>
				ExpertPathway
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:title"} content={"ExpertPathway"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				ExpertPathway
			</Override>
		</Components.Header>
		<Section padding="88px 0 88px 0" background="--color-darkL2">
			<Override slot="SectionContent" max-width="1220px" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="100%"
				padding="15px 15px 15px 15px"
				display="flex"
				flex-direction="column"
				justify-content="center"
				align-items="center"
			>
				<Text margin="0px 0px 24px 0px" font="--headline1" color="#ffffff">
					<Strong>
						Наші послуги
					</Strong>
				</Text>
			</Box>
			<Box
				width="100%"
				margin="30px 0px 30px 0px"
				border-radius="16px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6744384d9335410018c81ed7/images/Hoffy-Cycles.jpg?v=2024-11-25T13:59:53.772Z) 0% 0% /cover no-repeat scroll padding-box"
				min-height="336px"
				sm-min-height="200px"
			/>
			<Box
				width="50%"
				padding="15px 15px 15px 15px"
				display="flex"
				align-items="flex-start"
				justify-content="center"
				flex-direction="column"
				lg-width="50%"
				sm-width="100%"
			>
				<Text margin="0px 0px 16px 0px" font="--headline2" color="--light">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Ремонт велосипедів
					</Strong>
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--light" text-align="left">
					Налаштування гальм і передач.
					<br />
					<br />
					Ремонт коліс (центрування, заміна спиць).
					<br />
					<br />
					Заміна шин і камер.
					<br />
					<br />
					Обслуговування підвісок і вилок.
					<br />
					<br />
					Встановлення додаткового обладнання (багажники, дзеркала, фари тощо).
				</Text>
			</Box>
			<Box
				width="50%"
				padding="15px 15px 15px 15px"
				display="flex"
				align-items="flex-start"
				justify-content="center"
				flex-direction="column"
				lg-width="50%"
				sm-width="100%"
			>
				<Text margin="0px 0px 16px 0px" font="--headline2" color="--light">
					Ремонт мотоциклів
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--light" text-align="left">
					Діагностику та усунення несправностей двигуна.
					<br />
					<br />
					Ремонт ходової частини.
					<br />
					<br />
					Обслуговування гальмівної системи.
					<br />
					<br />
					Заміна мастил і фільтрів.
					<br />
					<br />
					Тюнінг і встановлення аксесуарів.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="15px 15px 15px 15px"
				display="flex"
				align-items="flex-start"
				justify-content="center"
				flex-direction="column"
				lg-width="50%"
				sm-width="100%"
			>
				<Text margin="0px 0px 16px 0px" font="--headline2" color="--light">
					Планове обслуговування
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--light" text-align="left">
					Регулярне ТО продовжує термін служби вашого транспорту та запобігає серйозним поломкам. Ми пропонуємо послуги для велосипедів і мотоциклів, включаючи перевірку стану вузлів, змащення деталей і діагностику систем.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="15px 15px 15px 15px"
				display="flex"
				align-items="flex-start"
				justify-content="center"
				flex-direction="column"
				lg-width="50%"
				sm-width="100%"
			>
				<Text margin="0px 0px 16px 0px" font="--headline2" color="--light">
					Терміновий ремонт
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--light" text-align="left">
					Виникла проблема перед поїздкою? Ми пропонуємо терміновий ремонт, щоб ви не залишились без транспорту в найважливіший момент.
				</Text>
			</Box>
		</Section>
		<Components.Steps />
		<Components.Footer>
			<Override slot="link5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});